
.App-logo {
  width: 350px;
  height: auto;
  pointer-events: none;
  margin-top: -400px;
}

.down-arrow {
  position: relative;
  top: -120px;
  z-index: 9;
  width: 30px;
  left: 50%;
  margin-left: -15px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

header {
  background-image: url(./coral-mountain-feature.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.blurb {
  max-width: 510px;
  font-size: 20px;
  line-height: 32px;
}

.contact h4 {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 24px;
}

.contact p {
  font-size: 16px;
  line-height: 24px;
}

.leadForm {
  margin-top: 50px;
}

.leadForm input {
  background: transparent;
  border: 1px solid #D1CCBD;
  color: #D1CCBD;
  padding: 10px 15px;
  border-radius: 0 !important;
  width: 300px;
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.leadForm input:hover,
.leadForm button:hover {
  background: #545150;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #D1CCBD;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #D1CCBD;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #D1CCBD;
}

.leadForm button {
  background: #D1CCBD;
  border: 1px solid #D1CCBD;
  color: #776E64;
  padding: 10px;
  border-radius: 0 !important;
  width: 145px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.leadForm button:hover {
  color: #D1CCBD;
  border-color: #545150;
}

.leadForm button:disabled {
  opacity: 0.5;
}

.copy {
  margin-right: 50px;
}
.error {
  color: red;
  font-size: 11px;
  position: relative;
  top: -15px;
}
