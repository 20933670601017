body {
  margin: 0;
  font-family: brother-1816, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 200;
  font-style: normal;
  color: #D1CCBD;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #776E64;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #D1CCBD;
  text-decoration: none;
}

a:hover {
  color: #D1CCBD;
  text-decoration: underline;
}

.hidden {
  display: none;
}