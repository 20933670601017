
.Siteplan-header {
  background: #1C1F2A;
}

.Siteplan-header p {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}

.Siteplan-header .logo {
  height: 40px;
  width: auto;
}

.plans, .faq, .footer {
  background: #1C1F2A;
}

.sites {
  background: #2B2E3C
}

.Siteplan .content,
.Siteplan-header .content {
  max-width: 1296px;
  margin: 0 auto;
  position: relative;
}

.Siteplan img,
.Siteplan-header .banner {
  max-width: 100%;
  width: 100%;
  display: block;
}

.Siteplan h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 300;;
}

.Siteplan h4 {
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 20px;
}

.Siteplan p {
  font-size: 16px;
  line-height: 32px;
}

.qa {
  margin-top: 40px;
}

.siteplan-wrapper {
  position: relative;
}

.label {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  color: #1C1F2A;
  border: 1px solid #1C1F2A;
  background-color: #D6D3D2;
  box-shadow: 0px 3px 6px #00000029;
  width: 180px;
  height: 36px;
  padding: 0;
  position: absolute;
  text-decoration: none !important;
}

.label:hover {
  background-color: #EBEBEB;
}

.label.dark {
  background-color: #1C1F2A;
  color: #D6D3D2;
  border: none;
}

.dark:hover {
  background-color: #000;
}

.label.dead {
  background-color: transparent;
  border: none;
  cursor: pointer;
  box-shadow: none;
}

.dead:hover {
  background-color: transparent;
}

.label.l1 {
  left: 20%;
  top: 5%; 
}

.label.l2 {
  left: 56%;
  top: 27%;
}

.label.l3 {
  left: 21%;
  top: 40%;
}

.label.l4 {
  left: 32%;
  top: 50%;
}

.label.l5 {
  left: 27%;
  top: 69%;
}

.label.l6 {
  left: 64%;
  top: 73%;
}

.label.l7 {
  left: 84%;
  top: 72%;
}

.label.l8 {
  left: 83%;
  top: 83%;
}

.close {
  background: transparent;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0px;
  top: 0px;
  border: none;
  padding: 0
}

.modal-text {
  font-size: 14px;
  color: #1C1F2A;
}

.modal-content {
  border-radius: 0;
}

.modal-content img {
  width: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 900px) {
  .Siteplan-header .logo {
    height: 30px;
  }

  .label {
    font-size: 10px;
    line-height: 22px;
    width: auto;
    height: 22px;
    padding: 0 10px;
  }

  .label.l7 {
    left: 75%;
    top: 65%;
  }

  .label.l6 {
    left: 55%;
    top: 73%;
  }

  .label.l8 {
    left: 75%;
    top: 83%;
  }

  .Siteplan h2 {
    font-size: 20px;
    line-height: 24px;
  }

  .Siteplan h4 {
    font-size: 14px;
    line-height: 24px;
  }

  .Siteplan p {
    font-size: 14px;
    line-height: 24px;
  }

  .Siteplan-header.py-4.row {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}