
.App-header.success {
  background-image: url(../coral-mountain-wave-gradient.jpg);
}

.App-header.success p {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}

.success .App-logo {
  margin-bottom: 60px;
}

.offset {
  margin-top: -120px;
}